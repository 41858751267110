import {
  CellFormatOptions,
  Icon,
  Table,
  TableCollapsable,
} from '@octano/global-ui';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useUserState } from '../../../hooks/useUserState';
import { getStudentScoreSummary } from '../requests';
import { StudentScoreGradeRow, StudentScoreRow } from '../types';
import AttendanceReqError from './AttendanceReqError';
import Loading from './Loading';
import NoStructureError from './NoStructureError';
import useStudentScoreColumns from './useStudentScoreColumns';

const NO_STRUCTURE =
  'Se requiere tener configuradas las evaluaciones y el examen';

export default function StudentScore({ sectionId }: { sectionId: string }) {
  const { userData } = useUserState();

  const { t } = useTranslation();
  const prefix = 'studentScores';
  const [isLoading, setIsLoading] = useState(false);
  const [errorOcurred, setErrorOcurred] = useState(false);
  const [noStructure, setNoStructure] = useState(false);
  const [studentScores, setStudentScores] = useState<StudentScoreRow[]>([]);

  const fetchSummary = useCallback(async () => {
    setIsLoading(true);

    const { data, error } = await getStudentScoreSummary(
      userData?.studentId ?? 0,
      sectionId,
    );

    setIsLoading(false);

    if (data) {
      const scores: StudentScoreRow[] = [];

      let final: StudentScoreRow = {
        grade: data.finalGrade.grade,
        date: data.finalGrade.date,
        name: data.finalGrade.name,
        percentage: data.finalGrade.percentage,
        type: 'final',
      };
      scores.push(final);

      if (data.examGrade) {
        let exam: StudentScoreRow = data.examGrade;
        exam.type = 'exam';
        scores.push(exam);

        let partial: StudentScoreRow = data.partialGrade;
        partial.type = 'partial';
        scores.push(partial);
      }

      scores.push(
        ...data.partialGrades.map<StudentScoreRow>((p) => ({
          type: 'partial-evaluation' as const,
          grade: p.grade,
          date: p.date,
          name: p.name,
          percentage: p.percentage,
          partialEvaluationType: p.type,
          grades: p.type === 'subevaluations' ? p.grades : undefined,
        })),
      );

      setStudentScores(scores);
    }

    console.log(error);
    if (
      error &&
      error.error.response?.status === 403 &&
      error.error.response?.data.message === NO_STRUCTURE
    )
      return setNoStructure(true);
    if (error) return setErrorOcurred(true);
  }, [sectionId, userData]);

  useEffect(() => {
    if (userData) fetchSummary();
  }, [userData, sectionId, fetchSummary]);

  const tableColumns = useStudentScoreColumns<StudentScoreRow>({
    isBold: (row) => row.type !== 'partial-evaluation',
  });

  const tableSubevaluationColumns = useStudentScoreColumns();

  if (isLoading) return <Loading />;

  if (noStructure) return <NoStructureError />;

  if (errorOcurred) return <AttendanceReqError />;
  return (
    <div className="pt-5 pb-5">
      <TableCollapsable
        data={studentScores}
        columns={tableColumns}
        isCollapsable={(row) =>
          row.type === 'partial-evaluation' &&
          row.partialEvaluationType === 'subevaluations'
        }
        secondColumn={{
          headerText: 'subevaluations',
          columnName: 'subevaluations',
          cellFormat: ({ row }: CellFormatOptions<StudentScoreRow>) => {
            if (
              !(
                row.type === 'partial-evaluation' &&
                row.partialEvaluationType === 'subevaluations'
              )
            ) {
              return null;
            }
            if (!row.grades) {
              return null;
            }

            return (
              <Table<StudentScoreGradeRow>
                borderless={false}
                color="transparent"
                striped
                columns={tableSubevaluationColumns}
                data={row.grades}
              />
            );
          },
        }}
        noResultsText={
          <div className="d-flex flex-column align-items-center justify-content-center pt-5">
            <Icon name="information" size={55} />
            <p className="fs-20 text-dark mt-4">{t(`${prefix}.noScore`)}</p>
          </div>
        }
      />
    </div>
  );
}
