import { Button, Icon, Modal } from '@octano/global-ui';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import {
  forwardRef,
  Ref,
  useState,
  useCallback,
  useImperativeHandle,
} from 'react';

export type ModalConfirmRegistrationContext = {
  //
};

export type ModalConfirmRegistrationMethods = {
  open: (ctx?: ModalConfirmRegistrationContext) => void;
  close: () => void;
};

interface ModalConfirmRegistrationProps {
  onConfirm?: () => void;
}

/**
 * Modal para confirmar la inscripción de asignaturas
 */
const ModalConfirmRegistration = (
  { onConfirm }: ModalConfirmRegistrationProps,
  ref: Ref<ModalConfirmRegistrationMethods>,
) => {
  const prefix = 'finishCourseRegistration';
  const { t } = useTranslation();

  const [, setContext] = useState<ModalConfirmRegistrationContext>();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const close = useCallback(() => {
    setIsLoading(false);
    setContext(undefined);
    setIsOpen(false);
  }, []);

  const open = useCallback((ctx?: ModalConfirmRegistrationContext) => {
    setIsLoading(false);
    setContext(ctx);
    setIsOpen(true);
  }, []);

  const handleConfirm = useCallback(() => {
    setIsLoading(true);
    !!onConfirm && onConfirm();
  }, [onConfirm]);

  useImperativeHandle(ref, () => ({
    open,
    close,
  }));

  return (
    <Modal isOpen={isOpen} toggle={close}>
      <div className="text-center">
        <Row>
          <Col xs={12}>
            <Icon name="information" size="50px" />
          </Col>
          <Col xs={12} className="pt-4">
            <span className="text-dark fs-22">
              {t(`${prefix}.modalConfirmation.title`)}
            </span>
          </Col>
          <Col xs={12} className="pt-3 pb-4">
            <span className="fs-18 text-light">
              {t(`${prefix}.modalConfirmation.body`)}
            </span>
          </Col>
        </Row>
        <Row>
          <Col
            xs={{ size: 12, order: 2 }}
            md={{ size: 6, order: 1 }}
            className="py-1 px-2"
          >
            <Button
              outlined
              fullwidth
              onClick={close}
              text={t(`common.btnCancel`)}
              disabled={isLoading}
            />
          </Col>
          <Col
            xs={{ size: 12, order: 1 }}
            md={{ size: 6, order: 2 }}
            className="py-1 px-2"
          >
            <Button
              fullwidth
              onClick={handleConfirm}
              text={t(`common.btnFinish`)}
              loading={isLoading}
            />
          </Col>
        </Row>
      </div>
    </Modal>
  );
};

export default forwardRef(ModalConfirmRegistration);
