import { Button, addToast } from '@octano/global-ui';
import { useCallback, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Col, Row } from 'reactstrap';

import { requestSendRegistration } from '../../../api/requests/courseRegistration';
import { useCourseSelectionState } from '../../../hooks/useCourseSelectionState';
import ModalConfirmRegistration, {
  ModalConfirmRegistrationMethods,
} from './ModalConfirmRegistration';
import ModalRegistrationErrors, {
  REGISTRATION_ERROR,
  ModalRegistrationMethodsErrors,
  EliminatedSection,
} from './ModalRegistrationErrors';

type SubmitRegistrationProps = {
  isDraft?: boolean;
};

const FinishRegistration = ({ helpEmail }: { helpEmail: string }) => {
  const confirmRegistrationRef = useRef<ModalConfirmRegistrationMethods>(null);
  const registrationErrorsRef = useRef<ModalRegistrationMethodsErrors>(null);

  const prefix = 'finishCourseRegistration';
  const { t } = useTranslation();
  const history = useHistory();
  const {
    removeAllItems,
    creditsPeriod,
    selectedCredits,
    selectedSections,
    resetCourseSelection,
    removeSection,
    setCourseState,
    selectedCareer,
  } = useCourseSelectionState();

  const [isFinishing, setIsFinishing] = useState<boolean>(false);

  /**
   * Valida que los créditos seleccionados esten dentro del rango permitido
   */
  const validateCourseRegistration = useCallback(
    (ctx: SubmitRegistrationProps): boolean => {
      if (selectedCredits < creditsPeriod.creditMin) {
        registrationErrorsRef?.current?.open({ error: REGISTRATION_ERROR.MIN });
        return false;
      } else if (selectedCredits > creditsPeriod.creditMax) {
        registrationErrorsRef?.current?.open({ error: REGISTRATION_ERROR.MAX });
        return false;
      }
      return true;
    },
    [selectedCredits, creditsPeriod],
  );

  /**
   * Despliega mensaje de error de secciones sin cupo
   * y las elimina de la selección
   */
  const errorSectionsWithoutQuota = useCallback(
    (sections: EliminatedSection[]) => {
      registrationErrorsRef?.current?.open({
        error: REGISTRATION_ERROR.QUOTA,
        eliminatedSections: sections,
      });

      sections.forEach((section) => {
        removeSection(section.id);
      });
      setCourseState(null);
    },
    [removeSection, setCourseState],
  );

  const submitRegistration = useCallback(
    async (ctx: SubmitRegistrationProps) => {
      if (isFinishing) {
        return;
      }
      setIsFinishing(true);
      try {
        if (validateCourseRegistration(ctx) && selectedCareer) {
          const { error } = await requestSendRegistration({
            isDraft: ctx?.isDraft,
            studyPlanEnrollmentId: selectedCareer.studyPlanEnrollmentId,
            mentionId: selectedCareer.mention?.id || null,
            enrollments: selectedSections.map((section) => {
              return {
                sectionId: section.id,
                semesterCourseId: section.course.semesterCourse!,
              };
            }),
          });

          if (error && error?.data?.sections) {
            errorSectionsWithoutQuota(error.data.sections);
          } else if (
            error &&
            [
              REGISTRATION_ERROR.MIN,
              REGISTRATION_ERROR.MAX,
              REGISTRATION_ERROR.CONTRACTED_EXCEEDED,
              REGISTRATION_ERROR.CONTRACTED_REQUIRED,
            ].includes(error?.data?.credits)
          ) {
            registrationErrorsRef?.current?.open({
              error: error?.data?.credits,
            });
          } else if (error) {
            throw new Error(REGISTRATION_ERROR.CONNECTION);
          }

          if (ctx?.isDraft) {
            return addToast({
              icon: 'success',
              color: 'success',
              text: t(`${prefix}.draftSaved`),
            });
          }

          resetCourseSelection();
          history.replace(
            `/enrollment/${selectedCareer.studyPlanEnrollmentId}/detail`,
          );
        }
      } catch (error) {
        registrationErrorsRef?.current?.open({
          error: REGISTRATION_ERROR.CONNECTION,
        });
      } finally {
        confirmRegistrationRef?.current?.close();
        setIsFinishing(false);
      }
    },
    [
      t,
      resetCourseSelection,
      isFinishing,
      history,
      selectedSections,
      selectedCareer,
      validateCourseRegistration,
      errorSectionsWithoutQuota,
    ],
  );

  return (
    <>
      {/* BOTONES PARA BORRAR TODA LA SELECCIÓN O CONFIRMAR LA INSCRIPCIÓN DE ASIGNATURAS */}
      <Row className="py-3">
        <Col xs={12} lg={4} className="py-1">
          <Button
            text={t(`${prefix}.btnEraseAllItems`)}
            onClick={removeAllItems}
            outlined
            disabled={isFinishing}
            color="danger"
            fullwidth
          />
        </Col>
        <Col xs={12} lg={4} className="py-1">
          <Button
            text={t(`${prefix}.btnDraft`)}
            onClick={() => submitRegistration({ isDraft: true })}
            loading={isFinishing}
            outlined
            fullwidth
          />
        </Col>
        <Col xs={12} lg={4} className="py-1">
          <Button
            text={t(`common.btnNext`)}
            onClick={() => confirmRegistrationRef?.current?.open({})}
            loading={isFinishing}
            fullwidth
          />
        </Col>
      </Row>

      {/* MODAL PARA INDICAR ERRORES EN LA INSCRIPCIÓN DE ASIGNATURAS */}
      <ModalRegistrationErrors
        ref={registrationErrorsRef}
        helpEmail={helpEmail}
      />

      <ModalConfirmRegistration
        ref={confirmRegistrationRef}
        onConfirm={() => submitRegistration({ isDraft: false })}
      />
    </>
  );
};

export default FinishRegistration;
