import { generatePath } from 'react-router-dom';

import { ReportType } from '../views/Reports/types';

// Endpoints disponibles agrupados por procesos
const AUTH = {
  CREDENTIAL_LOGIN: '/credential-login',
  REQUEST_ACTIVATION_EMAIL: '/check-activate-account',
  REQUEST_FORGOT_PASSWORD_EMAIL: '/recovery-password',
  ACTIVATE_ACCOUNT: '/activate-account',
  CHANGE_PASSWORD: '/reset-password',
  CHECK_PASSWORD_CHANGE_TOKEN: '/check-token',
};

const COURSE_ENROLLMENT = {
  STUDENT_INFORMATION: '/course-enrollment/student-information',
  CREDITS_SUMMARY: '/students-portal/course-enrollment/credits-summary',
  AVAILABLE_COURSES: `/students-portal/course-enrollment/available-courses`,
  DRAFT_SECTIONS: `/students-portal/course-enrollment-draft/draft-sections`,
  AVAILABLE_SECTIONS: '/students-portal/course-enrollment/available-sections',
  MODULES: '/students-portal/course-enrollment/time-modules',
  ENROLLMENT: '/students-portal/course-enrollment',
  ENROLLMENT_DRAFT: '/students-portal/course-enrollment-draft',
  ENROLLMENT_COURSE_RESUME: (studyPlanEnrollmentId: string | number) =>
    `/students-portal/course-enrollment/course-resume/${studyPlanEnrollmentId}`,
  ENROLLMENT_VERIFICATION:
    '/students-portal/course-enrollment/student-enrollment-verification',
};

const CURRICULUM = {
  // Obtiene información de la malla del estudiante y el avance que tiene en ésta
  PROGRESSION: (studyPlanEnrollmentId: number | string) =>
    `/students-portal/curriculum/${studyPlanEnrollmentId}`,
  PROGRESS: (studyPlanEnrollmentId: number) =>
    generatePath(
      '/students-portal/curriculum-progress/:studyPlanEnrollmentId',
      {
        studyPlanEnrollmentId,
      },
    ),
  COURSE_HISTORY: (studyPlanEnrollmentId: number) =>
    generatePath(
      '/students-portal/curriculum-progress/:studyPlanEnrollmentId/course-history',
      {
        studyPlanEnrollmentId,
      },
    ),
  COURSE_HISTORY_DOWNLOAD: (studyPlanEnrollmentId: number) =>
    generatePath(
      '/students-portal/curriculum-progress/:studyPlanEnrollmentId/course-history-document',
      {
        studyPlanEnrollmentId,
      },
    ),
};

const DEGREE_PROCESSES = {
  GET_DEGREE_PROCESS: (studyPlanEnrollmentId: number) =>
    generatePath('/students-portal/degree-processes/:studyPlanEnrollmentId', {
      studyPlanEnrollmentId,
    }),
};

const PERIODS = {
  PERIOD_CREDITS: '/students-portal/periods/credits',
};

const STUDENTS = {
  // Entrega el estado del alumno para poder inscribir asignaturas e información del periodo de inscripción
  CURRENT_STATUS: '/students/current-status',
  // Valida que la cuenta autenticada sea de tipo estudiante
  VALIDATE_ACCOUNT: '/students/validate-account',
};

const COURSE_SEARCHING = {
  SEARCH_STUDY_PLANS_FILTER: '/study-plans/filtered-list',
  SEARCH_COURSES_FILTER: '/course-searching/available-courses',
  SEARCH_SECTIONS_FILTER: '/course-searching/available-sections',
  CALENDAR_TIME_MODULES: '/course-searching/time-modules',
  TEACHERS: '/course-searching/professor-list',
  FACULTY: '/school/all',
  STUDY_PLANS: '/study-plans/school',
  CAMPUS: '/campus/all',
  MODALITY: '/modality/all',
};

const STUDENT_CERTIFICATES = {
  GET_ALL: '/students-portal/student-certificates',
  DOWNLOAD_BY_URL: (
    studyPlanEnrollmentId: string | number,
    certificateTypeCode: ReportType,
  ) =>
    `/students-portal/student-certificates/certificate-type/${studyPlanEnrollmentId}/${certificateTypeCode}`,
};

const STUDENT_MANAGEMENT = {
  STUDY_PLAN_INFORMATION: '/students-portal/academic-information',
  SECTIONS_INFORMATION: (studyPlanEnrollmentId: string | number) =>
    `/students-portal/course-enrollment/course-resume/${studyPlanEnrollmentId}`,
  GET_ATTENDANCE_SUMMARY: (
    studentId: number | string,
    sectionId: number | string,
  ) => `/assistance/assistance/detail/${studentId}/section/${sectionId}`,
  GET_SCORE_SUMMARY: (studentId: number | string, sectionId: number | string) =>
    `/grades/detail/${studentId}/section/${sectionId}`,
  GET_CAROUSEL_IMAGES: '/student-management/carousel',
};

const USER = {
  INFORMATION: '/course-searching/user-information',
};

const TUITION_CONTINUITY = {
  STUDENT_STATUS: (studyPlanEnrollmentId: number) =>
    generatePath(
      '/students-portal/tuition-continuity-process/:studyPlanEnrollmentId',
      { studyPlanEnrollmentId },
    ),
  CURRENT_STEP: (studyPlanEnrollmentId: number | string) =>
    `/students-portal/tuition-continuity-process/current-step/${studyPlanEnrollmentId}`,
  RECOVER_STUDENT_FILE: (studyPlanEnrollmentId: number | string) =>
    `/students-portal/tuition-continuity-process/student-files/${studyPlanEnrollmentId}`,
  SAVE_STUDENT_FILE: (studyPlanEnrollmentId: number | string) =>
    `/students-portal/tuition-continuity-process/student-files/${studyPlanEnrollmentId}`,
  RECOVER_SUSTAINER: (studyPlanEnrollmentId: number | string) =>
    `/students-portal/tuition-continuity-process/sustainer/${studyPlanEnrollmentId}`,
  SAVE_SUSTAINER: (studyPlanEnrollmentId: number | string) =>
    `/students-portal/tuition-continuity-process/sustainer/${studyPlanEnrollmentId}`,
  TARIFF_STEP: (studyPlanEnrollmentId: number | string) =>
    `/students-portal/tuition-continuity-process/tariffs/${studyPlanEnrollmentId}`,
  TARIFF_CALCULATE: (studyPlanEnrollmentId: number | string) =>
    `/students-portal/tuition-continuity-process/tariffs/${studyPlanEnrollmentId}/calculate`,
  TARIFF_STEP_SAVE: (studyPlanEnrollmentId: number | string) =>
    `/students-portal/tuition-continuity-process/tariffs/${studyPlanEnrollmentId}`,
  SIGN_DOCUMENTATION_STEP: (studyPlanEnrollmentId: number | string) =>
    `/students-portal/tuition-continuity-process/sign-documentation/${studyPlanEnrollmentId}`,
  SIGN_DOCUMENTATION_DOCUMENT_GENERATED: (
    studyPlanEnrollmentId: number | string,
  ) =>
    `/students-portal/tuition-continuity-process/sign-documentation/${studyPlanEnrollmentId}/documents`,
  SIGN_DOCUMENTATION_UPLOAD_DOCUMENTATION: (
    studyPlanEnrollmentId: number | string,
    uploadMethod: string,
  ) =>
    `/students-portal/tuition-continuity-process/sign-documentation/${studyPlanEnrollmentId}/upload-documentation/${uploadMethod}`,
  SIGN_DOCUMENTATION_STEP_SAVE: (studyPlanEnrollmentId: number | string) =>
    `/students-portal/tuition-continuity-process/sign-documentation/${studyPlanEnrollmentId}`,
};

const PARAMETERS = {
  ACTIVITIES: '/activity',
  COUNTRIES: '/countries',
  INSTITUTION_TYPES: '/postulation/institutions-types',
  REGION_AND_COMMUNES: '/region/all',
  SEARCH_RESPONSIBLE: '/section/search-responsable',
};

const PETITIONS = {
  LIST: '/students-portal/student-petition',
  FORM: '/students-portal/student-petition/form',
  CREATE: '/students-portal/student-petition',
  VIEW: (petitionId: number) =>
    `/students-portal/student-petition/${petitionId}`,
};

const INTERIM_DEGREE_PROCESS_ACTIVITIES = {
  DETAILS: ({ interimDegreeId }: { interimDegreeId: number | string }) =>
    `/students-portal/interim-degree-process/activities/${interimDegreeId}`,
  FORM: ({
    studyPlanEnrollmentId,
  }: {
    studyPlanEnrollmentId: number | string;
  }) =>
    `students-portal/interim-degree-graduation-process/activities/form/${studyPlanEnrollmentId}`,
};

export {
  AUTH,
  COURSE_ENROLLMENT,
  CURRICULUM,
  DEGREE_PROCESSES,
  PERIODS,
  STUDENTS,
  COURSE_SEARCHING,
  STUDENT_MANAGEMENT,
  USER,
  STUDENT_CERTIFICATES,
  TUITION_CONTINUITY,
  PARAMETERS,
  PETITIONS,
  INTERIM_DEGREE_PROCESS_ACTIVITIES,
};
