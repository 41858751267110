import { Button } from '@octano/global-ui';
import { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Col, Row } from 'reactstrap';
import { requestEnrollmentStatus } from '../../../api/requests/courseRegistration';
import { ReactComponent as FinishImage } from '../../../assets/svg/finish.svg';
import SelectedSections from '../../../components/courseEnrollment/SelectedSections';
import { useCourseSelectionState } from '../../../hooks/useCourseSelectionState';
import CourseList from './CourseList';
import FinishRegistration from './FinishRegistration';
import WeekCalendar from './WeekCalendar';

enum ENROLLMENT_STATUS {
  PENDING = 'pending',
  FINISHED = 'finished',
}
const prefix = 'courseRegistration.finished';

const EnrollmentProcess = ({ helpEmail }: { helpEmail: string }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const {
    selectedCareer,
    selectedSections,
    removeSection,
  } = useCourseSelectionState();
  const [loading, setLoading] = useState<boolean>(true);
  const [status, setStatus] = useState<ENROLLMENT_STATUS>();

  const getEnrollmentStatus = useCallback(async () => {
    if (selectedCareer) {
      const { data } = await requestEnrollmentStatus(
        selectedCareer.studyPlanEnrollmentId,
        selectedCareer?.mention?.id,
      );
      if (data?.data) {
        if (data.data.is_enrolled) {
          setStatus(ENROLLMENT_STATUS.FINISHED);
        } else {
          setStatus(ENROLLMENT_STATUS.PENDING);
        }
      }
      setLoading(false);
    }
  }, [setStatus, setLoading, selectedCareer]);

  useEffect(() => {
    getEnrollmentStatus();
  }, [getEnrollmentStatus]);

  if (loading) {
    return null;
  }
  if (selectedCareer && status === ENROLLMENT_STATUS.FINISHED) {
    return (
      <Row className="py-5">
        <Col xs={12} className="d-flex justify-content-center">
          <div className="w-100 text-center" style={{ maxWidth: '400px' }}>
            <FinishImage />
            <div className="pt-3">
              <span className="fs-20 text-dark">{t(`${prefix}.title`)}</span>
            </div>
            <p className="fs-18 py-3">
              <Trans t={t}>{t(`${prefix}.body`)}</Trans>
            </p>
            <Button
              text={t(`${prefix}.btn`)}
              onClick={() =>
                history.push(
                  `/enrollment/${selectedCareer.studyPlanEnrollmentId}/detail`,
                )
              }
              fullwidth
            />
          </div>
        </Col>
      </Row>
    );
  } else if (selectedCareer && status === ENROLLMENT_STATUS.PENDING) {
    return (
      <Row className="py-4">
        <Col xs={12} lg={5} className="mb-4">
          <CourseList />
        </Col>
        <Col xs={12} lg={7}>
          <WeekCalendar />
          <SelectedSections
            selectedSections={selectedSections}
            removeSection={removeSection}
          />
          <FinishRegistration helpEmail={helpEmail} />
        </Col>
      </Row>
    );
  } else {
    // En caso de no poder validar el estado del proceso, no se despliega contenido
    return null;
  }
};

export default EnrollmentProcess;
