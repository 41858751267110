import { Button, addToast } from '@octano/global-ui';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Col, Row } from 'reactstrap';

import {
  getSignDocumentationDocumentGenerated,
  saveSignDocumentationInfo,
} from '../../../../../api/requests/tuitionContinuity';
import SectionTitle from '../../../../../components/text/SectionTitle';
import { useStepState } from '../../../../../hooks/useStepState';
import DocumentCard from './DocumentCard';
import { RequestedDocument } from './signDocumentationTypes';

export interface SignDocumentationProps {
  studyPlanEnrollmentId: number;
  documents: RequestedDocument[];
}

/**
 * Componente que corresponde a la vista principal del paso de contrato y subida de archivos
 */
const SignDocumentation = ({
  studyPlanEnrollmentId,
  documents: requestedDocumentsProp,
}: SignDocumentationProps) => {
  const prefix = 'tuitionContinuity.signDocumentation';
  const { t } = useTranslation();
  const { nextStep } = useStepState();

  const history = useHistory();
  const [requestedDocuments, setRequestedDocuments] = useState<
    RequestedDocument[]
  >(requestedDocumentsProp);

  const [isSubmitting, setSubmitting] = useState(false);
  const [showErrors, setShowErrors] = useState<boolean>(false);

  useEffect(() => window.scrollTo(0, 0));

  const onSubmit = async () => {
    setSubmitting(true);
    if (requestedDocuments.some((r) => r.document.required && !r.uploaded)) {
      setShowErrors(true);
    } else {
      setShowErrors(false);
      const { error } = await saveSignDocumentationInfo(studyPlanEnrollmentId);
      if (!error) {
        nextStep();
      } else {
        addToast({
          icon: 'error',
          color: 'danger',
          text: t(`${prefix}.nextStepError`),
        });
      }
    }
    setSubmitting(false);
  };

  const updateState = useCallback(
    (
      property: 'downloaded' | 'uploaded',
      requestedDocument: RequestedDocument,
    ) => {
      setRequestedDocuments((requestedDocuments) => {
        const index = requestedDocuments.findIndex(
          (r) => r === requestedDocument,
        );
        if (index < 0) {
          return [...requestedDocuments];
        }
        const newRequestedDocuments = [...requestedDocuments];
        const newRequestedDocument: RequestedDocument = {
          ...requestedDocument,
          [property]: true,
        };
        newRequestedDocuments[index] = newRequestedDocument;
        return newRequestedDocuments;
      });
    },
    [],
  );

  const setDownloaded = useCallback(
    (requestedDocument: RequestedDocument) =>
      updateState('downloaded', requestedDocument),
    [updateState],
  );
  const setUploaded = useCallback(
    (requestedDocument: RequestedDocument) =>
      updateState('uploaded', requestedDocument),
    [updateState],
  );

  const downloadDocument = useCallback(
    async (requestedDocument: RequestedDocument) => {
      const { data, error } = await getSignDocumentationDocumentGenerated(
        studyPlanEnrollmentId,
        requestedDocument.document.code,
      );
      if (data && !error) {
        window.open(data.url);
        setDownloaded(requestedDocument);
      } else if (error) {
        addToast({
          icon: 'error',
          color: 'danger',
          text: t(`common.errors.download`),
        });
      }
    },
    [studyPlanEnrollmentId, t, setDownloaded],
  );

  return (
    <>
      <Row>
        <Col xs={12} className="pb-3">
          <SectionTitle text={t(`${prefix}.title`)} />
        </Col>
      </Row>

      {/* Listado de documentos a adjuntar */}
      <Row className="mx-n2 align-items-end">
        {requestedDocuments.map((requestedDocument) => {
          const size = requestedDocument.document.generated ? 'md' : 'sm';
          return (
            <Col xs={12} md={6} lg={size === 'md' ? 6 : 3} className="mb-3">
              <DocumentCard
                tuitionContinuityProcessId={studyPlanEnrollmentId}
                errorText={undefined}
                onDownload={downloadDocument}
                onUploaded={setUploaded}
                requestedDocument={requestedDocument}
                requiredDocument={requestedDocuments?.find(
                  (e) =>
                    e?.document?.id === requestedDocument?.requiredDocumentId,
                )}
                size={size}
                showErrors={showErrors}
              />
            </Col>
          );
        })}
      </Row>

      <Row className="py-5 justify-content-end">
        <Col xs={12} lg={4} className="pb-2 order-2 order-lg-1">
          <Button
            type="button"
            outlined
            onClick={() => history.push('/tuition-process')}
            text={t(`common.actions.cancel`)}
            loading={isSubmitting}
            fullwidth
          />
        </Col>

        <Col xs={12} lg={4} className="pb-2 order-1 order-lg-2">
          <Button
            text={t(`common.actions.next`)}
            onClick={onSubmit}
            loading={isSubmitting}
            fullwidth
          />
        </Col>
      </Row>
    </>
  );
};

export default SignDocumentation;
