import { Button, Icon, SearchBox, Table } from '@octano/global-ui';
import { deburr } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  requestAvailableCourses,
  requestDraftSections,
} from '../../../api/requests/courseRegistration';
import { useCourseSelectionState } from '../../../hooks/useCourseSelectionState';
import { useDataFilter } from '../../../hooks/useDataFilter';
import { CourseType } from '../../../types/courseRegistrationRequestTypes';
import SectionList from './SectionList';

const CourseList = () => {
  const RESULTS_PER_PAGE = 10;
  const prefix = 'courseList';
  const { t } = useTranslation();
  const [courses, setCourses] = useState<CourseType[]>([]);
  const filterFunction = useCallback(
    (row: CourseType, text: string) =>
      deburr(row.name).toUpperCase().includes(deburr(text).toUpperCase()) ||
      deburr(row.shortening).toUpperCase().includes(deburr(text).toUpperCase()),
    [],
  );
  const { selectedCourse, setCourseState, selectedCareer, addSection } =
    useCourseSelectionState();

  const { results, totalResults, searchText, setSearchText, setPage } =
    useDataFilter<CourseType>(courses, filterFunction, RESULTS_PER_PAGE);

  const getAvailableCourses = useCallback(async () => {
    if (selectedCareer) {
      const [
        { data, error },
        { data: draftSections, error: errorDraftSections },
      ] = await Promise.all([
        requestAvailableCourses(
          selectedCareer.studyPlanEnrollmentId,
          selectedCareer.mention?.id,
        ),
        requestDraftSections(
          selectedCareer.studyPlanEnrollmentId,
          selectedCareer.mention?.id,
        ),
      ]);
      if (data) {
        setCourses(data);
        setPage(1);
      }
      if (error) {
        setCourses([]);
        // TODO: que mensaje se desplegara al existir un error?
      }

      if (draftSections?.length && !errorDraftSections) {
        draftSections.forEach((e) => addSection(e));
      }
    }
  }, [setPage, selectedCareer, addSection]);

  useEffect(() => {
    getAvailableCourses();
  }, [getAvailableCourses, selectedCareer]);

  return (
    <div>
      {selectedCourse?.id ? (
        <SectionList />
      ) : (
        <>
          <Table
            striped={false}
            height={700}
            subHeader={
              <SearchBox
                name="search_course"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                placeholder={t(`${prefix}.searchPlaceholder`)}
              />
            }
            columns={[
              {
                columnName: 'shortening',
                headerText: t(`${prefix}.code`),
                width: '200px',
              },
              {
                columnName: 'name',
                headerText: t(`${prefix}.name`),
                cellFormat: ({ row }) => (
                  <div className="text-uppercase">
                    <span>{row.name}</span>
                    <br />
                    <span className="fs-12 text-light">
                      {row.types.map((type, i) =>
                        i === 0 ? `${type.name} ` : ` - ${type.name}`,
                      )}
                    </span>
                  </div>
                ),
              },
              {
                columnName: 'credits',
                headerText: t(`${prefix}.credits`),
              },
              {
                columnName: 'id',
                headerText: '',
                cellFormat: ({ row }) => (
                  <div
                    className="cursor-pointer"
                    onClick={() => {
                      setCourseState(row);
                    }}
                  >
                    <Icon name="chevron_right" />
                  </div>
                ),
              },
            ]}
            data={results}
          />

          {totalResults > 0 && results.length < totalResults && (
            <div className="pt-2">
              <Button
                text={t(`${prefix}.btnMore`)}
                size="md"
                onClick={() => setPage((prev) => prev + 1)}
                outlined
                fullwidth
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default CourseList;
