import React from 'react'
import { FieldValues, useController, UseControllerProps } from 'react-hook-form'
import { FormGroup, FormText, Label, UncontrolledTooltip } from 'reactstrap'
import { formatters, Formatters } from '../../../utils/formatters'
import { Icon } from '../../Icon'

export interface TextInputProps<T> extends UseControllerProps<T> {
  type?: 'text' | 'password'
  label: string
  tooltip?: string | JSX.Element
  errorText?: string
  disabled?: boolean
  autocomplete?: string
  placeholder?: string
  ref?: React.LegacyRef<HTMLInputElement>
  value?: any
  onChange?: React.ChangeEventHandler<HTMLInputElement>
  onBlur?: React.FocusEventHandler<HTMLInputElement>
  formatter?: keyof typeof Formatters | ((value: any) => string)
  minLength?: number
  maxLength?: number
  readOnly?: boolean
}

export const TextInput = <T extends FieldValues>(props: TextInputProps<T>) => {
  let controlledProps
  let controlledFieldState
  if (props.control) {
    const { field, fieldState } = useController<T>(props)
    controlledProps = field
    controlledFieldState = fieldState
  }
  const {
    type,
    label,
    tooltip,
    errorText,
    disabled,
    autocomplete,
    placeholder,
    formatter,
    minLength,
    maxLength,
    readOnly
  } = props

  const { name, ref, value, onChange } = controlledProps || props

  const onBlur = props.onBlur || controlledProps?.onBlur

  const format =
    formatter && typeof formatter === 'string'
      ? formatters[formatter]
      : formatter

  return (
    <FormGroup>
      <div className='g-text-input-container'>
        <input
          className='g-text-input form-control'
          id={name}
          type={type}
          name={name}
          disabled={disabled}
          autoComplete={autocomplete}
          placeholder={placeholder}
          ref={ref}
          value={
            value !== null && value !== undefined && value !== '' && format
              ? format(value)
              : value || ''
          }
          onChange={onChange}
          onBlur={onBlur}
          style={{ paddingRight: tooltip ? '40px' : '20px' }}
          minLength={minLength}
          maxLength={maxLength}
          readOnly={readOnly}
        />

        <Label for={name} className='g-input-label'>
          {label.toUpperCase()}
        </Label>

        <div className='g-input-border' />

        {tooltip && (
          <div className='g-input-tooltip' id={`inputTooltip_${name}`}>
            <Icon name='information_input' size='1rem' />
            <UncontrolledTooltip
              placement='bottom'
              target={`inputTooltip_${name}`}
              fade={false}
            >
              {tooltip}
            </UncontrolledTooltip>
          </div>
        )}
      </div>

      <FormText className='g-input-error' color='danger'>
        {errorText || controlledFieldState?.error?.message || ''}
      </FormText>
    </FormGroup>
  )
}

TextInput.defaultProps = {
  type: 'text',
  disabled: false,
  value: ''
}
