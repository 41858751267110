import { Button, Icon, Modal } from '@octano/global-ui';
import { Trans, useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import {
  forwardRef,
  Ref,
  useState,
  useCallback,
  useImperativeHandle,
  useMemo,
} from 'react';

export enum REGISTRATION_ERROR {
  MIN = 'MINIMUM_CREDITS_INSUFFICIENT',
  MAX = 'MAXIMUM_CREDITS_EXCEEDED',
  CONTRACTED_REQUIRED = 'CONTRACTED_CREDITS_REQUIRED',
  CONTRACTED_EXCEEDED = 'CREDITS_EXCEEDED_CONTRACTED',
  CONNECTION = 'connection',
  QUOTA = 'quota',
}

const errorMessages = {
  [REGISTRATION_ERROR.MIN]: 'finishCourseRegistration.errors.min',
  [REGISTRATION_ERROR.MAX]: 'finishCourseRegistration.errors.max',
  [REGISTRATION_ERROR.CONTRACTED_REQUIRED]:
    'finishCourseRegistration.errors.contractedRequired',
  [REGISTRATION_ERROR.CONTRACTED_EXCEEDED]:
    'finishCourseRegistration.errors.contractedExceeded',
  [REGISTRATION_ERROR.CONNECTION]: 'finishCourseRegistration.errors.connection',
  [REGISTRATION_ERROR.QUOTA]: 'finishCourseRegistration.errors.quota',
};

export type EliminatedSection = {
  id: number;
  name: string;
  course: { id: number; code: string; name: string };
};

export type ModalRegistrationContextErrors = {
  error: REGISTRATION_ERROR;
  eliminatedSections?: EliminatedSection[];
};

export type ModalRegistrationMethodsErrors = {
  open: (ctx?: ModalRegistrationContextErrors) => void;
  close: () => void;
};

interface ModalRegistrationPropsErrors {
  helpEmail: string;
  onClose?: () => void;
}

/**
 * Modal para confirmar la inscripción de asignaturas
 */
const ModalRegistrationErrors = (
  { helpEmail, onClose }: ModalRegistrationPropsErrors,
  ref: Ref<ModalRegistrationMethodsErrors>,
) => {
  const { t } = useTranslation();
  const [context, setContext] = useState<ModalRegistrationContextErrors>();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const errorValidation = useMemo(() => context?.error, [context?.error]);
  const eliminatedSections = useMemo(() => context?.eliminatedSections ?? [], [
    context?.eliminatedSections,
  ]);

  const close = useCallback(() => {
    setIsOpen(false);
    setContext(undefined);
    !!onClose && onClose();
  }, [onClose]);

  const open = useCallback((ctx?: ModalRegistrationContextErrors) => {
    setContext(ctx);
    setIsOpen(true);
  }, []);

  useImperativeHandle(ref, () => ({
    open,
    close,
  }));

  return (
    <Modal isOpen={isOpen} toggle={close}>
      {errorValidation && (
        <div className="text-center">
          <Row>
            <Col xs={12}>
              <Icon name="warning" size="50px" />
            </Col>
            <Col xs={12} className="pt-4">
              <span className="text-dark fs-22">
                {t(`${errorMessages[errorValidation]}.title`)}
              </span>
            </Col>
            <Col xs={12} className="pt-3 pb-4">
              <span className="fs-18 text-light fw-300">
                <Trans
                  i18nKey={`${errorMessages[errorValidation]}.body`}
                  values={{ contactEmail: helpEmail }}
                  components={{ email: <span className="text-link" /> }}
                />
              </span>
            </Col>
            {errorValidation === REGISTRATION_ERROR.QUOTA &&
              eliminatedSections.length > 0 && (
                <Col xs={12} className="text-left pb-5">
                  <p className="fw-300 text-medium fs-14">
                    {t(
                      `finishCourseRegistration.errors.${errorValidation}.eliminatedCourses`,
                    )}
                  </p>
                  <div
                    className="outlined-box-primary fs-16 text-dark px-1"
                    style={{ minHeight: '120px' }}
                  >
                    <ul>
                      {eliminatedSections.map((section) => {
                        return (
                          <li key={section.id}>
                            {section.course.code}-{section.name}{' '}
                            {section.course.name}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </Col>
              )}
            <Col xs={12} className="py-1 px-2">
              <Button fullwidth onClick={close} text={t(`common.btnGotIt`)} />
            </Col>
          </Row>
        </div>
      )}
    </Modal>
  );
};

export default forwardRef(ModalRegistrationErrors);
