import {
  CourseType,
  EnrollmentType,
  StudentInfoType,
} from '../../types/courseRegistrationRequestTypes';
import { SelectedSection } from '../../types/courseSelectionTypes';
import { SectionType } from '../../types/sectionType';
import { COURSE_ENROLLMENT, STUDENTS } from '../endpoints';
import request from '../request';

/**
 * Valida que el usuario autenticado sea de tipo estudiante
 * @returns
 */
export const requestValidateAccount = (studyPlanEnrollmentId: string) => {
  const url = STUDENTS.VALIDATE_ACCOUNT;
  const params = { studyPlanEnrollmentId };
  return request<{
    data: { isStudent: boolean };
  }>(url, {
    method: 'get',
    params,
  });
};

/**
 * Información del alumno y carrera a la que pertenece
 */
export const requestStudentInformation = () => {
  return request<{
    data: StudentInfoType;
  }>(COURSE_ENROLLMENT.STUDENT_INFORMATION, {
    method: 'get',
  });
};

/**
 * Obtiene las asignaturas disponibles por carrera
 * para que el alumno pueda inscribirlas en el período actual
 */
export const requestAvailableCourses = (
  studyPlanEnrollmentId: number,
  mentionId?: number,
) => {
  return request<CourseType[]>(COURSE_ENROLLMENT.AVAILABLE_COURSES, {
    method: 'get',
    params: { studyPlanEnrollmentId, mentionId },
  });
};

/**
 * Obtiene las secciones disponibles de una asignatura
 */
export const requestAvailableSections = (params: {
  courseId: number;
  studyPlanEnrollmentId: string | number;
}) => {
  return request<SectionType[]>(COURSE_ENROLLMENT.AVAILABLE_SECTIONS, {
    method: 'get',
    params,
  });
};

/**
 * Guarda la información asociada a la inscripción de asignaturas.
 */
export const requestSendRegistration = ({
  isDraft = false,
  ...data
}: {
  studyPlanEnrollmentId: number;
  mentionId: number | null;
  enrollments: EnrollmentType[];
  isDraft?: boolean;
}) => {
  return request(
    isDraft ? COURSE_ENROLLMENT.ENROLLMENT_DRAFT : COURSE_ENROLLMENT.ENROLLMENT,
    {
      method: 'POST',
      data,
    },
  );
};

/**
 * Obtiene las secciones guardadas en borrador
 */
export const requestDraftSections = (
  studyPlanEnrollmentId: number,
  mentionId?: number,
) => {
  return request<SelectedSection[]>(COURSE_ENROLLMENT.DRAFT_SECTIONS, {
    method: 'get',
    params: { studyPlanEnrollmentId, mentionId },
  });
};

/**
 * Valida si el proceso de inscripción de asignaturas fue finalizado
 */
export const requestEnrollmentStatus = (
  studyPlanEnrollmentId: number,
  mentionId?: number,
) => {
  return request<{
    data: { is_enrolled: boolean };
  }>(COURSE_ENROLLMENT.ENROLLMENT_VERIFICATION, {
    method: 'get',
    params: {
      studyPlanEnrollmentId,
      mentionId,
    },
  });
};
