export const moneyFormatter = (
  locale: string = 'es-CL',
  currency: string = 'CLP',
) => {
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency,
  });
};

export const TariffFormatter = (
  value?: number | string,
  data?: {
    id: number;
    currency: string;
    locale: string;
  },
  defaultValue = '',
) => {
  if (value === null || value === undefined) {
    return defaultValue;
  }
  const numberValue =
    typeof value === 'string' ? Number(value.replace(/\D/g, '')) : value;
  return moneyFormatter(data?.locale, data?.currency).format(numberValue);
};

export const formatToOnlyPositiveIntegers = (value: string) => {
  let amount: string | number = value.replace(/\D/g, '').trim();

  if (!amount?.trim()) {
    return '0';
  }

  amount = parseInt(amount);
  if (amount < 1) {
    return '0';
  }

  return `${amount}`;
};

export const formatToOnlyPositiveScaledIntegers = (value: string) => {
  let salary: string | number = value.replace(/\D/g, '').trim();

  if (!salary?.trim()) {
    return '';
  }

  salary = parseInt(salary);
  if (salary < 1) {
    return '';
  }

  if (`${salary}`?.length === 1) {
    return `0,0${salary}`;
  }

  if (`${salary}`?.length === 2) {
    return `0,${salary}`;
  }

  const integers = `${salary}`?.slice(0, `${salary}`?.length - 2);
  const decimals = `${salary}`?.slice(-2);

  return `${integers},${decimals}`;
};
